import {formatter, incrementedDateRange, minMax, periodStart} from "@/services/reportingDates";
import {computed} from "vue";

/**
 * This function returns a function and labels necessary for timespan-specific graphs.
 * @param {Object} props - The reporting props passed to the given graph, e.g.
 * .{
 *     filters: {type: Object, default: () => {}},
 *     by: {type: String, default: 'week'},
 * }
 * @param {Object} data - The Vue ref data returned from the reporting endpoint.
 * @returns {getTimeSeriesData, labels} - The function for getting time series data
 * and The labels needed for given time series.
 */
export function useTimeSeries(props, data) {
    const getPeriodStart = row => periodStart(props.by)(row);
    const formatSegmentStart = start => formatter(props.by)(start);
    const formatSegmentForRow = row => formatSegmentStart(getPeriodStart(row));
    const getPeriodStarts = () => incrementedDateRange(...minMax(data.value, getPeriodStart), {
        by: 1,
        unit: props.by
    });
    const labels = computed(() => getPeriodStarts().map(formatSegmentStart));

    function getTimeSeriesData(rows) {
        const rowForSegmentLabel = seg => rows.find(x => formatSegmentForRow(x) === seg);
        return Array.from(labels.value.map(rowForSegmentLabel)).map(x => x && x.count || 0);
    }

    return {getTimeSeriesData, labels}
}
