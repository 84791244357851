<script setup lang="ts">
import {defineProps} from 'vue';
const props = defineProps({
    values: Array<any>
})
const formatNumber = (value: number, isInteger: boolean|null = null) => {
    if (value >= 1_000_000) {
        return (isInteger ? Math.round(value / 1_000_000) : (value / 1_000_000).toFixed(1)) + 'M';
    } else if (value >= 1_000) {
        return (isInteger ? Math.round(value / 1_000) : (value / 1_000).toFixed(1)) + 'k';
    }
    return value.toFixed(1).toString();
}

</script>

<template>
    <div class="d-flex flex-column gap-4 justify-content-center">
        <div v-for="value in props.values" :key="value.label" class="number-container">
            <div>{{value.label}}</div>
            <div>
                <span class="big-number">{{formatNumber(value.value)}}</span>
                <span class="unit text-muted">{{value.unit}}</span>
            </div>
        </div>
    </div>
</template>

<style scoped>
.big-number {
    font-size: 4rem;
}
.unit {
    font-size: 1.3rem;
}
.number-container {
    text-align: center;
}
</style>
