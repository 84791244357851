import * as moment from "moment";

export type ISODateString = string;
export type Integer = number;
export type TimeUnit = 'day' | 'month' | 'week'

export function weekStart(row: {year: number, week_of_year: number}): ISODateString {
    return moment().year(row.year).weeks(row.week_of_year).isoWeekday(0).format('YYYY-MM-DD');
}

export function formatWeek(start: ISODateString): string {
    const momentStart = moment(start);
    return `${momentStart.format('l')}`;
}
export function monthStart(row: {year: number, month: number}) {
    return moment().year(row.year).month(row.month - 1).date(1).format('YYYY-MM-DD');
}

export function formatMonth(start: ISODateString) {
    return moment(start).format('YYYY-MMM')
}

export function date(row: {date: string}) {
    return moment(row.date).format('YYYY-MM-DD');
}

export function formatDate(date: ISODateString) {
    return moment(date).format('l')
}

export function minMax(rows: any[], selector: ((row: any) => ISODateString)): [string, string] {
    const sorted = rows.map(selector).sort();
    return [sorted[0], sorted[sorted.length - 1]]
}

export function incrementedDateRange(startString: ISODateString, endString: ISODateString, {by, unit}: {by: Integer, unit: TimeUnit}): ISODateString[] {
    const range = [];
    let currentDate = moment(startString);
    while (currentDate <= moment(endString)) {
        range.push(currentDate.format('yyyy-MM-DD'));
        console.log('reportingDates currentDate', currentDate)
        currentDate = currentDate.add(by, unit);
    }
    return range;
}

export const periodStart = (by: TimeUnit) =>
    by === 'month' ? monthStart :
    by === 'week' ? weekStart :
    by === 'day' ? date :
    () => '';


export const formatter = (by: TimeUnit) =>
    by === 'month' ? formatMonth :
    by === 'week' ? formatWeek :
    by === 'day' ? formatDate :
    () => '';
