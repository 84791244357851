<script setup lang="ts">
import {computed, defineProps} from 'vue';
import {Bar} from "vue-chartjs";
import {chartColors} from "@/Constants";
import {BarElement, CategoryScale, Chart as ChartJS, Legend, LinearScale, Title, Tooltip} from "chart.js";
    const props = defineProps({
        datasets: {type: Array<any>},
        labels: {type: Array<any>},
        id: {type: String},
    });

    ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale);
    const colors = Object.values(chartColors);
    const chartData = computed(() => ({
        labels: props.labels,
        datasets: props.datasets.map((dataset: any, index) => (
        { ...dataset, backgroundColor: colors[index]}
    ))
    }))
    const chartOptions = {
        responsive: true,
        plugins: {
            legend: {
                display: false
            },
        }
    }

</script>

<template>
    <Bar
        :id="id"
        :options="chartOptions"
        :data="chartData"
    />
</template>

<style scoped>

</style>
