import {useReportData} from "@/composables/useReportData";
import {useOtherGrouping} from "@/composables/useOtherGrouping";
import {computed} from "vue";

export function usePieController({key, store, minPercent, maxCount, label, value, filters, limit, limitIncrement}) {
    limit = limit || 10;
    limitIncrement = limitIncrement || limit;
    const {data, load, listen, isLoading} = useReportData(key, store, filters, {limit, limitIncrement});
    listen();
    load().then(() => console.log(`report:${key}:data`, data.value.map(x => value(x) || 0)));
    const labels = computed(() => data.value.map(label));
    const datasets = computed(() => [{data: data.value.map(value)}]);
    return {labels, datasets, isLoading};
}
