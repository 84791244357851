import {ref, Ref} from 'vue';
import {Store} from 'vuex';
import {getPath, getQueryString} from "@/composables/useReportData";

export function useReportDownloadUrls(
    store: Store<any>,
    filters: Ref<any>,
    config: {limit: number, limitIncrement: number} = {limit: 10, limitIncrement: null},
    ) {
    const limit = ref(config.limit);
    function downloadUrl(report_key) {
        const accountId = store.getters.currentAccountOverview.id;
        if (!filters.value || !accountId || !report_key) return '';
        const queryString = getQueryString(() => filters.value, () => limit.value);
        return `${getPath(accountId, () => report_key)}.csv?${queryString}`;
    }
    return {
        downloadUrl
    }
}
