<script setup>
/* eslint-disable */
import store from '../store';
import {computed, ref, watch} from "vue";
import ClickHistory from "@/components/charts/ClickHistory.vue";
import ClicksByCountry from "@/components/charts/ClicksByCountry.vue";
import DashboardContainer from "@/components/Controls/charts/DashboardContainer.vue";
import ClicksByCollection from "@/components/charts/ClicksByCollection.vue";
import TopArticles from "@/components/charts/TopArticles.vue";
import TotalClicks from "@/components/charts/TotalClicks.vue";
import TopDomains from "@/components/charts/TopDomains.vue";
import ByUrlParam from "@/components/charts/ByUrlParam.vue";
import ArticleSummary from "@/components/charts/ArticleSummary.vue";
import ReportFilters from "@/components/Reporting/ReportFilters.vue";
import ReportHeader from "@/components/Reporting/ReportHeader.vue";
import ClickByUserAgent from "@/components/charts/ClickByUserAgent.vue";
import ClicksByReferrer from "@/components/charts/ClicksByReferrer.vue";
import {useReportDownloadUrls} from "@/composables/useReportDownloadUrl";
import TopArticlesCards from "@/components/charts/TopArticlesCards.vue";

const props = defineProps({
    collectionId: {type: String, required: false, default: ''},
    qpKey: {type: String, required: false, default: ''},
    qpValue: {type: String, required: false, default: ''},
    timespan: {type: String, required: false, default: 'last_month'},
    start: {type: String, required: false, default: null},
    groupBy: {type: String, required: false, default: 'week'},
    end: {type: String, required: false, default: null},
    age: {type: Number, required: false, default: 90},
    referrers: {type: String, required: false, default: ''},
    referrerMode: {type: String, required: false, default: ''},
    userAgents: {type: String, required: false, default: ''},
    userAgentMode: {type: String, required: false, default: ''},
})

console.log('query props', props)

const colors = ['purple', 'violet', 'blue', 'cyan', 'green', 'yellow', 'orange', 'red']


const filters = ref(null)

const {downloadUrl} = useReportDownloadUrls(store, filters, {limit: 1000});

const features = computed(() => store.getters.accountFeatures);
</script>

<template>
    <div class="dashboard">
        <ReportHeader class="mt-2 ms-4 me-4 mb-2" />
        <ReportFilters @filters-changed="x => filters = x" v-bind="props"/>
        <div class="m-4">
            <div class="dashboard__body row">
                <dashboard-container :span="1" label="Total Account Clicks">
                    <TotalClicks :filters="filters" />
                </dashboard-container>
                <dashboard-container :span="1" label="Articles">
                    <ArticleSummary :filters="filters" />
                </dashboard-container>
                <dashboard-container :span="2" label="By Collection" :download-url="downloadUrl('by_collection')" download-name="by-collection.csv">
                    <ClicksByCollection :filters="filters"/>
                </dashboard-container>
                <dashboard-container
                    :span="4"
                    label="Click History"
                    :expand="true"
                    :download-url="downloadUrl(`by_collection_by_${filters && filters.groupBy}`)"
                    download-name="click-history.csv">
                    <ClickHistory :filters="filters" class="bar-chart" :by="filters && filters.groupBy || props.groupBy"/>
                </dashboard-container>
                <dashboard-container :span="2" label="By Country" :download-url="downloadUrl('by_country')" download-name="by-country.csv">
                    <ClicksByCountry :filters="filters" class="pie-chart" />
                </dashboard-container>
                <dashboard-container :span="2" label="By Referrer" :download-url="downloadUrl('by_referrer')" download-name="by-referrer.csv">
                    <ClicksByReferrer :filters="filters" class="bar-chart" :by="groupBy"/>
                </dashboard-container>
                <dashboard-container :span="4" label="Top Articles" expand :download-url="downloadUrl('top_articles')" download-name="top-articles.csv">
                    <TopArticles :filters="filters"/>
                </dashboard-container>
                <dashboard-container :span="4" label="Top Domains" expand :download-url="downloadUrl('top_domains')" download-name="top-domains.csv">
                    <TopDomains :filters="filters"/>
                </dashboard-container>
                <dashboard-container :span="4" label="URL Parameters" expand  :download-url="downloadUrl('by_url_params')" download-name="by-url-params.csv">
                    <ByUrlParam :filters="filters"/>
                </dashboard-container>
                <dashboard-container :span="4" label="By User Agent" :download-url="downloadUrl('by_user_agent')" download-name="by-user-agent.csv">
                    <ClickByUserAgent :filters="filters"/>
                </dashboard-container>
<!--                <dashboard-container :span="1" label="Medium Numbers">-->
<!--                    <big-numbers :values="[{value: 42, unit: 'flops', label: 'Happiness'}, {value: 1337, unit: 'codes', label: 'Leetness'}]"></big-numbers>-->
<!--                </dashboard-container>-->
<!--                <dashboard-container :span="1" label="Small Numbers">-->
<!--                    <numbers :values="[{value: 42, unit: 'flops', label: 'Happiness'}, {value: 1337, unit: 'codes', label: 'Leetness'}, {value: 1337, unit: 'codes', label: 'Leetness'}, {value: 1337, unit: 'codes', label: 'Leetness'}]"></numbers>-->
<!--                </dashboard-container>-->

            </div>
        </div>
    </div>
</template>
<style scoped>
</style>

